<template>
  <div class="storyCard">
    <router-link class="card-link" :to="`/?story_view=${creator.user}`">
      <div class="section-carousel-filter-card" prevent>
        <div class="section-carousel-filter-img-wrap" :style="`--picture:url(${encode_url(image)})`"></div>
        <div class="section-carousel-filter-img-tittle" ellipsis>
          {{ creator.name }}
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ["creator"],
  data: function() {
    return {
      image: `https://myfans1a.s3.amazonaws.com/creators/landing/thumbnails/${this.creator.user}_story.jpg`,
    };
  },
  methods: {
    getLandingData: function() {
      if (!this.creator.landing) return this.image;

      if (!this.creator.landing.stories) return this.image;

      this.image = this.creator.landing.stories.src;

      return this.creator.landing.stories.src;
    },
    getMedia: function() {
      const img = new Image();
      img.src = this.getLandingData();

      img.onload = () => {};

      img.onerror = () => {
        this.getFisrtFromFiles();
      };
    },
    getFisrtFromFiles: function() {
      const _media = this.creator.files.find(function(m) {
        return m.type.includes("image");
      });

      if (_media) {
        this.image = _media.src;
      }
    },
  },
  mounted: function() {
    this.getMedia();
  },
};
</script>
